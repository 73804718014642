<template>
  <transition name="fade">
    <div>
      <template v-if="tmploadedData === false">
        <b-row
          class="match-height d-flex flex-column align-items-center justify-content-center"
          style="height: calc(50vh)"
        >
          <b-col
            class="d-flex flex-column align-items-center justify-content-center p-3"
          >
            <h3>{{ $t(`common.loading`) }}</h3>
            <div class="demo-inline-spacing">
              <b-spinner class="mr-1" variant="info" />
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-if="tmploadedData === true && regionnumber === 0">
        <b-row class="match-height">
          <b-col
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <h3>{{ $t('common.nodata') }}</h3>
            <b-img fluid :src="imgUrl" />
          </b-col>
        </b-row>
      </template>
      <template v-if="tmploadedData === true && regionnumber > 0">
        <transition name="fade">
          <b-row>
            <b-col
              v-for="regionitem in regionlist"
              :key="regionitem.regionkey"
              :class="getRegionisShow(regionitem.showregion)"
              sm="6"
              md="4"
              lg="3"
              xl="auto"
              style="width: 300px"
            >
              <b-card no-body>
                <b-card-body>
                  <b-card-title class="font-weight-bold">
                    {{ regionitem.name }}
                  </b-card-title>
                </b-card-body>
                <b-img
                  class="mb-1"
                  fluid
                  :src="require('@/assets/images/slider/03.jpg')"
                  alt="Card image cap"
                />
                <b-card-footer class="text-center py-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :to="{
                      name: 'devicecontrolsetting',
                      params: { regionkey: regionitem.regionkey },
                    }"
                    variant="success"
                    class="d-flex justify-content-center align-items-center font-weight-bold"
                  >
                    <font-awesome-icon icon="gauge-high" class="mr-1" />
                    <span class="align-middle">{{ $t('common.enter') }}</span>
                  </b-button>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </transition>
      </template>
    </div>
  </transition>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    loadedData: {
      type: Boolean,
      default: () => false,
    },
    deviceregionData: {
      type: Object,
      default: () => {},
    },
    showregionData: {
      type: String,
      default: () => 'ALL',
    },
    regiondeviceslist: {
      type: Object,
      default: () => {},
    },
    caseregion: {
      type: Object,
      default: () => {},
    },
    casepn: {
      type: String,
      default: () => 'ALL',
    },
  },
  data() {
    return {
      data: {},
      showregion: 'all',
      showregionname: '',
      showmode: 'simple',
      downImg: require('@/assets/images/illustration/notfounddata.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/illustration/notfounddata-w.png')
        return this.downImg
      }
      return this.downImg
    },
    tmploadedData: {
      get() {
        return this.loadedData
      },
      set(val) {
        this.$emit('update:loadedData', val)
      },
    },
    tmpdeviceregionData: {
      get() {
        return this.deviceregionData
      },
      set(val) {
        this.$emit('update:deviceregionData', val)
      },
    },
    tmpregiondeviceslist: {
      get() {
        return this.regiondeviceslist
      },
      set(val) {
        console.log(val)
        this.$emit('update:regiondeviceslist', val)
      },
    },
    tmpcaseregion: {
      get() {
        return this.caseregion
      },
      set(val) {
        console.log(val)
        this.$emit('update:caseregion', val)
      },
    },
    regionnumber() {
      try {
        if (this.regiondeviceslist === undefined) {
          return 0
        }
        return Object.keys(this.regiondeviceslist).length
      } catch (error) {
        console.log(error)
        return 0
      }
    },
    regionlist() {
      try {
        if (this.caseregion === undefined) {
          return []
        }
        const tmpArray = []
        Object.keys(this.caseregion).forEach(regionkey => {
          const tmpobj = this.caseregion[regionkey]
          tmpobj.regionkey = regionkey
          if (this.showregion === 'all') {
            tmpobj.showregion = true
          } else if (this.showregion === regionkey) {
            tmpobj.showregion = true
          } else {
            tmpobj.showregion = false
          }
          tmpArray.push(tmpobj)
        })
        tmpArray.sort((a, b) => {
          try {
            if (a.index !== undefined) {
              if (a.index > b.index) {
                return 1
              }
            }
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return 0
      }
    },
    regionAlllist() {
      try {
        if (this.caseregion === undefined) {
          return []
        }
        const tmpArray = []
        Object.keys(this.caseregion).forEach(regionkey => {
          const tmpobj = this.caseregion[regionkey]
          tmpobj.regionkey = regionkey
          tmpArray.push(tmpobj)
        })
        tmpArray.sort((a, b) => {
          try {
            if (a.index !== undefined) {
              if (a.index > b.index) {
                return 1
              }
            }
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return 0
      }
    },
  },
  mounted() {
    try {
      this.showregionname = this.$t('common.all')
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      //
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getRegionisShow(isshow) {
      if (this.showregion === 'all') {
        return ''
      }
      if (isshow !== true) {
        return 'd-none'
      }
      return ''
    },
    changeShowRegion(regionkey) {
      this.showregion = regionkey
      if (regionkey === 'all') {
        this.showregionname = this.$t('common.all')
      } else {
        this.showregionname = this.tmpcaseregion[regionkey].name
      }
    },
    changeShowMode(mode) {
      if (mode !== undefined) {
        this.showmode = mode
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.dark-layout .dropdown-menu .dropdown-item {
  font-weight: 600;
}
</style>
