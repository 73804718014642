<template>
  <section id="dashboard-casestatus">
    <b-row class="match-height">
      <!-- Company Table Card -->
      <transition name="fade">
        <b-col v-if="Loaded === true" lg="12">
          <h1>{{ case_name }}</h1>
        </b-col>
      </transition>
      <b-col lg="12">
        <CaseRegionSettingList
          :loaded-data.sync="Loaded"
          :deviceregion-data.sync="deviceRegionCalculator"
          :regiondeviceslist.sync="regiondeviceslist"
          :showregion-data.sync="showcategory_region"
          :caseregion.sync="case_region"
          :casepn="casepn"
        />
      </b-col>
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'
import CaseRegionSettingList from './CaseRegionSettingList.vue'

export default {
  components: {
    BRow,
    BCol,
    CaseRegionSettingList,
  },
  data() {
    return {
      pagename: 'casestatus',
      statisticsItems: {},
      caselistArray: [],
      caselistObject: {},
      regiondeviceslist: {},
      showcategory_region: '',
      deviceRegionCalculator: {},
      random_number: 0,
      Loaded: false,
      case_name: '',
      case_type: '',
      case_region: {},
      casepn: '',
    }
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.webConfigs[document.location.hostname]
      ? $themeConfig.webConfigs[document.location.hostname]
      : $themeConfig.app
    return {
      pagename: 'dashboard',
      appName,
    }
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getCasesInfo() {
      try {
        const vuethis = this
        const queryParams = {
          pn: this.casepn,
          callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
        }
        this.$socket.emit('casecloud_getcaseinfo', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = vuethis.$route.params.casepn
        if (
          this.casepn === undefined ||
          this.casepn === null ||
          this.casepn === ''
        ) {
          this.$swal({
            icon: 'error',
            title: this.$t('error_common.notfound_pn'),
            showConfirmButton: true,
            timer: 2000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.$router.push({ name: 'casesetting' }).catch(error => {})
          })
          return
        }
        setTimeout(vuethis.getCasesInfo(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply_casecloud_getcaseinfo',
            this.socketevent_reply_casecloud_getcaseinfo
          )
        } else {
          this.sockets.unsubscribe('reply_casecloud_getcaseinfo')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_casecloud_getcaseinfo(params) {
      try {
        const vuethis = this
        if (
          vuethis.$router !== undefined &&
          vuethis.$router.history !== undefined &&
          vuethis.$router.history.current
        ) {
          if (vuethis.$router.history.current.name !== undefined) {
            if (
              vuethis.$router.history.current.name !== 'casesettingregionlist'
            ) {
              return
            }
          }
        }
        if (params === undefined) {
          vuethis.error_loading = true
        }
        if (
          params.type === undefined ||
          params.type === 'error' ||
          params.msg === undefined
        ) {
          vuethis.error_loading = true
        }
        if (vuethis.error_loading === true) {
          let errormsg = ''
          if (params.msg === 'user is undefined') {
            const userData = Tientech_getCookie('userData')
            if (
              userData !== undefined &&
              userData !== null &&
              userData !== ''
            ) {
              window.location.reload()
              return
            }
          }
          if (params.msg === 'database_disconnect') {
            errormsg = this.$t('error_common.database_disconnect')
          } else {
            errormsg = this.$t('error_common.insufficient_permissions')
          }

          this.$swal({
            icon: 'error',
            title: errormsg,
            showConfirmButton: false,
            timer: 1000,
            buttonsStyling: false,
          }).then(result => {
            // this.$router.push({ name: 'dashboard' })
          })
          return
        }

        if (vuethis.Loaded === false) {
          vuethis.total_cases = 0
          vuethis.caselistObject = {}
          vuethis.caselistArray = []
          vuethis.Loaded = true
        }

        vuethis.total_cases = Object.keys(params.msg).length
        const tmpcases = params.msg
        Object.keys(tmpcases).forEach(pn => {
          try {
            let obj = {
              sys: {
                pn,
              },
            }
            obj = Object.assign([], obj, tmpcases[pn])
            obj = Tientech_CaseInit(obj)
            const tmpobj = Tientech_CaseDeviceInit(obj)
            vuethis.deviceRegionCalculator = {}
            vuethis.deviceRegionCalculator = {
              ...tmpobj.deviceRegionCalculator,
            }
            vuethis.showcategory_region = 'ALL'
            vuethis.showcategory_region = tmpobj.showcategory_region
            vuethis.regiondeviceslist = {}
            vuethis.regiondeviceslist = { ...tmpobj.regiondeviceslist }

            if (vuethis.caselistObject[pn] === undefined) {
              vuethis.caselistArray.push(obj)
              vuethis.caselistObject[pn] = {
                index: vuethis.caselistArray.length - 1,
              }
            } else {
              vuethis.caselistArray[vuethis.caselistObject[pn].index] = obj
            }
            vuethis.case_name =
              vuethis.caselistArray[
                vuethis.caselistObject[pn].index
              ].case_info.name
            vuethis.case_type =
              vuethis.caselistArray[
                vuethis.caselistObject[pn].index
              ].case_info.type
            vuethis.case_region = {}
            vuethis.case_region = {
              ...vuethis.caselistArray[vuethis.caselistObject[pn].index]
                .case_region,
            }
            if (vuethis.case_name !== '') {
              document.title = `${vuethis.case_name} - ${vuethis.$t(
                `pages.${vuethis.pagename}.pagetitle`
              )} - ${vuethis.appName}`
            } else {
              document.title = `${vuethis.$t(
                `pages.${vuethis.pagename}.pagetitle`
              )} - ${vuethis.appName}`
            }
            vuethis.$forceUpdate()
          } catch (error) {
            console.log(error)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
